























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getBridgeEvents, getProperty, getPropertyTraffic } from '@/api/properties'
import Pagination from '@/components/Pagination/index.vue'
import {
  createFilter,
  FilterOperator,
  FilterType, getSortItems,
  prepareListFilters
} from '@/utils/filter'
import { parseTime } from '@/utils'

@Component({
  name: 'PropertyTraffic',
  components: {
    Pagination
  }
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any
  private total = 0
  private list = []
  private listLoading = false
  private parseTime = parseTime
  private listQuery = createFilter()


  created() {
    this.getList()
  }

  private tableRowClassName(data: any) {
    const { row } = data
    return 'success-row'
  }

  private handleSortChange(params: any = {}) {
  }

  private async getList() {
    this.listLoading = true
    try {
      const { data } = await getPropertyTraffic({id:this.property.id})
      this.list = data.collection || []
      this.total = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }


}
